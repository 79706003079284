const modal = document.querySelector('.poll_modal');

document.addEventListener('DOMContentLoaded', () => {
    if ( modal ) {
        modal.style.display = 'flex';

        setTimeout(showModalClose, 5000);

        console.log('attaching click')
        modal.querySelector('.close').addEventListener('click', e => {
           e.preventDefault();
           modal.style.display = 'none';
        });
    }
});

function showModalClose() {
    const close = document.querySelector('.poll_modal .close');
    if ( close ) {
        close.style.display = 'inline';
    }
}
